// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Logout from './Logout';
import Auth from './Auth';
import BcgViewer from './Bcg_Viewer';
import DeviceHeader from './Device_Header';
import FileProcessor from './FileProcessor';
import Docs from './Docs';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/" element={<Home />} />
        <Route path="/bcg" element={<BcgViewer/>}/>
        <Route path="/device" element={<DeviceHeader/>}></Route>
        <Route path="/updatetime" element={<FileProcessor/>}></Route>
        <Route path="/docs" element={<Docs/>}></Route>
        
      </Routes>
    </Router>
  );
}

export default App;
