

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
function DeviceHeader() {
  const [files, setFiles] = useState([]);
  const [dateTime, setDateTime] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [deviceIds, setDeviceIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
 

  const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];

  const devices = {
    'Option 1': ['DOZ-4670', 'DOZ-2217', 'DP-6014'],
    'Option 2': ['DOZ-8129', 'DOZ-8126', 'DP-6045'],
    'Option 3': ['DOZ-8055', 'DOZ-8039', 'DOZ-7986'],
    'Option 4': ['DOZ-7965', 'DP-6080', 'DP-6081'],
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  const handleDateTimeChange = (event) => {
    setDateTime(event.target.value);
  };
  const handleBackClick = () => {
    navigate('/home');
};
  const handleOptionChange = (event) => {
    const selected = event.target.value;
    setSelectedOption(selected);
    setDeviceIds(devices[selected]);
    setSelectedDeviceId(''); // Clear the selected device ID when the option changes
  };

  const handleDeviceIdChange = (event) => {
    setSelectedDeviceId(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files[]', file);
    });
    formData.append('dateTime', dateTime);
    formData.append('selectedOption', selectedOption);
    formData.append('deviceId', selectedDeviceId);

    // Upload files, datetime, selected option, and device ID to the server
    fetch('http://localhost:5001/file', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setMessage('Files and data uploaded successfully!');
        console.log('Files and data uploaded successfully:', data);
      })
      .catch((error) => {
        setLoading(false);
        setMessage('Error uploading files and data.');
        console.error('Error uploading files and data:', error);
      });
  };

  return (
    <div style={styles.container}>
    <form onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.formGroup}>
        <label htmlFor="option-select" style={styles.label}>Choose an Option:</label>
        <select
          id="option-select"
          value={selectedOption}
          onChange={handleOptionChange}
          style={styles.select}
        >
          <option value="" disabled>Select an option</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {selectedOption && (
        <div style={styles.formGroup}>
          <label htmlFor="device-select" style={styles.label}>Select a Device ID:</label>
          <select
            id="device-select"
            value={selectedDeviceId}
            onChange={handleDeviceIdChange}
            style={styles.select}
          >
            <option value="" disabled>Select a device</option>
            {deviceIds.map((deviceId) => (
              <option key={deviceId} value={deviceId}>
                {deviceId}
              </option>
            ))}
          </select>
        </div>
      )}
      <div style={styles.formGroup}>
        <label htmlFor="file-upload" style={styles.label}>Upload Files or Folder:</label>
        <input
          type="file"
          id="file-upload"
          multiple
          webkitdirectory="true"
          onChange={handleFileChange}
          style={styles.fileInput}
        />
      </div>
      <div style={styles.formGroup}>
        <label htmlFor="date-time" style={styles.label}>Enter Date and Time:</label>
        <input
          type="text"
          id="date-time"
          value={dateTime}
          onChange={handleDateTimeChange}
          placeholder="YYYY-MM-DD HH:MM:SS"
          style={styles.input}
        />
      </div>
      <button type="submit" style={styles.button} disabled={loading}>
        {loading ? 'Uploading...' : 'Upload'}
      </button>
      {loading && <div style={styles.loader}>Uploading...</div>}
      {message && <div style={styles.message}>{message}</div>}
    </form>
      <button onClick={handleBackClick} style={{
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginBottom: '20px',
        fontSize: '16px'
    }}>
        Back
    </button>
    </div>
  );
}

const styles = {
    container:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
    },
  form: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#333',
  },
  select: {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  fileInput: {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  button: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
  },
  loader: {
    marginTop: '10px',
    fontSize: '16px',
    color: '#007bff',
  },
  message: {
    marginTop: '10px',
    fontSize: '16px',
    color: '#28a745',
  },
};

export default DeviceHeader;

