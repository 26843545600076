import React, { useState, useRef } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { useNavigate } from 'react-router-dom';

const BcgViewer = () => {
  const [files, setFiles] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [currentGraphIndex, setCurrentGraphIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleBackClick = () => {
    navigate('/home');
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const items = e.dataTransfer.items;
    const uploadedFiles = [];
    alert("files uploaded")

    // Iterate through all items in the DataTransferItemList
    for (let i = 0; i < items.length; i++) {
      if (items[i].webkitGetAsEntry && items[i].webkitGetAsEntry().isDirectory) {
        // If the item is a directory
        const directoryReader = items[i].webkitGetAsEntry().createReader();
        directoryReader.readEntries((entries) => {
          entries.forEach((entry) => {
            entry.file((file) => {
              uploadedFiles.push(file);
            });
          });
          setFiles(uploadedFiles);
        });
      } else {
        // If the item is a file
        const file = items[i].getAsFile();
        if (file) {
          uploadedFiles.push(file);
        }
      }
    }
    setFiles(uploadedFiles);
  };

  const handleUpload = async () => {
    if(files.length>6){
        alert("cannot upload more than 6 files right now");
        return;
    }
    if (files.length > 0) {
      setLoading(true);
      const formData = new FormData();
      files.forEach((file) => formData.append('files', file)); // Append all files to the FormData object

      try {
        const idToken = localStorage.getItem('id_token');

        // Set up the headers with the id_token
        const headers = {
          'auth-token': idToken,
        };

        // Send the POST request with the formData and headers
        const response = await axios.post(
          'https://analytics.dozeeapp.com/upload',
          formData,
          { headers }
        );
        

        // Store the graph data in state
        setGraphData(response.data);
        setCurrentGraphIndex(0); // Reset to the first graph data set
      } catch (error) {
        console.log(error)
        console.error('Error uploading files:', error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileChange = (e) => {
    console.log(e.target.files)
    const uploadedFiles = Array.from(e.target.files);
    setFiles(uploadedFiles);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Function to handle 'Next' button click
  const handleNextClick = () => {
    if (currentGraphIndex < graphData.length - 1) {
      setCurrentGraphIndex((prevIndex) => prevIndex + 1);
    }
  };

  // Function to handle 'Previous' button click
  const handlePrevClick = () => {
    if (currentGraphIndex > 0) {
      setCurrentGraphIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <div className="container">
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
        onChange={handleFileChange}
        onClick={(e) => e.stopPropagation()} // Prevent file input from triggering the click event on parent div
      />
      
      <div
        onDrop={handleFileDrop}
        onClick={handleClick}
        onDragOver={(e) => e.preventDefault()}
        style={{
          border: '2px dashed #ccc',
          padding: '20px',
          width: '300px',
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        {files.length > 0
          ? `${files.length} files selected`
          : 'Drag and Drop your folder here or click to select files'}
      </div>

      <button onClick={handleUpload} disabled={loading} style={{ display: 'block', margin: '20px auto' }}>
        {loading ? 'Uploading...' : 'Upload'}
      </button>

      {loading && (
        <div
          className="loader"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '24px',
            color: '#007bff',
          }}
        >
          Loading...
        </div>
      )}

      <div className="graph">
        {graphData.length > 0 && (
          <>
            <Plot
              data={graphData[currentGraphIndex].data}
              layout={{
                ...graphData[currentGraphIndex].layout,
                autosize: true,
              }}
              style={{ width: '110rem', height: '50rem' }}
            />
            
          </>
        )}
      </div>
      <div>
      <div>
              <button
                onClick={handlePrevClick}
                disabled={currentGraphIndex === 0}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginRight: '10px',
                  fontSize: '16px',
                }}
              >
                Previous
              </button>
              <button
                onClick={handleNextClick}
                disabled={currentGraphIndex === graphData.length - 1}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                Next
              </button>
            </div>
        <button
          onClick={handleBackClick}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginBottom: '20px',
            fontSize: '16px',
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default BcgViewer;