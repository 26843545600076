// src/components/Login.js
import React, { useEffect } from 'react';

const Login = () => {
  const protocol = window.location.protocol;

  // Get the current host URL
  const hostUrl = `${protocol}//${window.location.host}`;
  console.log(hostUrl);
  
  useEffect(() => {
    window.location.href = `https://dozeeapp.auth.ap-south-1.amazoncognito.com/oauth2/authorize?client_id=4qcpg3f0ig5u1a0bh3l075foiq&response_type=code&scope=email+openid+phone&redirect_uri=${hostUrl}/auth`;
  }, []);

  return (
    <div>
      <h1>Redirecting...</h1>
    </div>
  );
};

export default Login;
