// src/components/Logout.js
import React, { useEffect } from 'react';

const Logout = () => {
  const protocol = window.location.protocol;

  // Get the current host URL
  const hostUrl = `${protocol}//${window.location.host}`;

  useEffect(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    const logoutUrl = `https://dozeeapp.auth.ap-south-1.amazoncognito.com/logout?client_id=4a7piqbdt5aeskhi99pkllpm1h&logout_uri=${hostUrl}`;
    window.location.href = logoutUrl;
  }, []);

  return (
    <div>
      <h1>Logging out...</h1>
    </div>
  );
};

export default Logout;
