import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const isLoggedIn = !!localStorage.getItem('id_token'); // Check if id_token is present

  return (
    <ul>
      {!isLoggedIn && (
        <li>
          <Link to="/login">Login</Link>
        </li>
      )}
      {isLoggedIn && (
        <>
          <li>
            <Link to="/logout">Logout</Link>
          </li>
          
        </>
      )}
    </ul>
  );
};

export default Navbar;
