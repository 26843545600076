// src/components/Auth.js
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const Auth = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code');
    console.log(code);
    if (code) {
      const clientId = '4qcpg3f0ig5u1a0bh3l075foiq';
      const clientSecret='1boih0ohv243p1iernt3121eue4lnpbjm9ns2hsp05f1obkccfgb'
      const redirectUri = window.location.origin+'/auth';
      console.log(redirectUri)
      const tokenUrl = `https://dozeeapp.auth.ap-south-1.amazoncognito.com/oauth2/token`;

      const data = {
        grant_type: 'authorization_code',
        client_id: clientId,
        code: code,
        redirect_uri: redirectUri,
        client_secret:clientSecret
      };

      const formBody = Object.keys(data)
        .map(
          key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');

      fetch(tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
      })
        .then(response => response.json())
        .then(data => {
          if (data.access_token) {
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('id_token', data.id_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            
            // Redirect to the home page or any other page
            window.location.href = '/home';
          } else {
            console.error('Error fetching tokens:', data);
          }
        })
        .catch(error => console.error('Error:', error));
    }
    else{
      window.location.href='/home'
    }
  }, [searchParams]);

  return (
    <div>
      <h1>Logging in...</h1>
    </div>
  );
};

export default Auth;
